<template>
	<b-modal
		id="delete-faq"
		ref="delete-faq-modal"
		title="Delete FAQ"
		:no-close-on-backdrop="true"
		@show="onReset"
		@hidden="onReset"
		size="md"
	>
		<div class="row">
			<div class="col-md-12">
				<h4 class="mb-4">Are you sure you want to delete this?</h4>
				<p><strong>Question</strong>: {{ form.question }}</p>
				<p><strong>Created By</strong>: {{ form.createdBy }}</p>
				<p>
					<strong>Date Created</strong>:
					{{ form.dateCreated | toDateTimeString }}
				</p>
			</div>
		</div>

		<template v-slot:modal-footer>
			<div class="w-100 text-right">
				<b-button
					v-if="!form.isDeleted"
					size="sm"
					variant="warning"
					class="text-white mr-2"
					@click.prevent="submit('soft_delete')"
				>
					Soft Delete
				</b-button>
				<b-button
					size="sm"
					variant="danger"
					@click.prevent="submit('hard_delete')"
				>
					Permanent Delete
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
// API and DAO
import faqsDAO from '../../../database/faqs';

// Util
import { DateUtil } from '../../../utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'delete-faq',
	data() {
		return {
			form: {
				id: '',
				question: '',
				answer: '',
				createdBy: '',
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: '',
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			},
		};
	},
	mounted() {
		EventBus.$on('showDeleteFaqDialog', (item) => {
			this.$bvModal.show('delete-faq');
			this.form = item;
		});
	},
	methods: {
		async submit(deleteType) {
			try {
				if (deleteType == 'soft_delete') {
					await faqsDAO.softDelete(this.form.id);
					this.$toaster.success('FAQ has been soft deleted!');
				} else {
					await faqsDAO.forceDelete(this.form.id);
					this.$toaster.success('FAQ has been deleted!');
				}

				this.$bvModal.hide('delete-faq');
				EventBus.$emit('onDeleteSuccess');
			} catch (error) {
				this.$toaster.error('Error deleting FAQ.');
			}
		},
		onReset() {
			this.form = {
				id: '',
				question: '',
				answer: '',
				createdBy: this.loggedUser.id,
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			};
		},
	},
	beforeDestroy() {
		EventBus.$off('showDeleteFaqDialog');
		this.onReset();
	},
};
</script>