<template>
	<b-modal
		id="add-faq"
		ref="add-faq-modal"
		title="Create New FAQ"
		:no-close-on-backdrop="true"
		@show="onReset"
		@hidden="onReset"
		@ok="handleOk"
		ok-title="Create"
		size="lg"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<b-form ref="form" @submit.stop.prevent="onSubmit">
			<b-form-group
				id="question-input-group"
				label="Question"
				label-for="question"
			>
				<b-form-textarea
					id="question"
					v-model="form.question"
					type="text"
					name="Question"
					placeholder="Enter question"
					v-validate="'required'"
				/>
				<span v-show="errors.has('Question')" class="help-block">{{
					errors.first('Question')
				}}</span>
				<span v-show="isQuestionAlreadyExists" class="help-block"
					>Question is already exists</span
				>
			</b-form-group>

			<b-form-group id="answer-input-group" label="Answer" label-for="answer">
				<vue-editor
					class="mb-2"
					id="answer"
					v-model="form.answer"
					:editorOptions="editorSettings"
				/>
				<input
					type="text"
					name="Answer"
					v-model="form.answer"
					v-validate="'required'"
					hidden
				/>
				<span v-show="errors.has('Answer')" class="help-block">{{
					errors.first('Answer')
				}}</span>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
// API
import api from '@/api/faqApi';

// Util
import { DateUtil } from '@/utils/dateutil';
import { VueEditor, Quill } from 'vue2-editor';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

export default {
	name: 'add-faq',
	components: { VueEditor, Loading },
	data() {
		return {
			form: {
				question: '',
				answer: '',
				createdBy: '',
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: '',
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			},
			categories: [],
			questions: [],
			editorSettings: {
				modules: {
					// videoResize: {},
					imageResize: {},
				},
			},

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		isQuestionAlreadyExists() {
			if (this.form.question) {
				const questions = this.questions.filter(
					(item) => item.toLowerCase() === this.form.question.toLowerCase()
				);
				return questions.length > 0 ? true : false;
			}

			return false;
		},
	},
	mounted() {
		EventBus.$on('showAddFaqDialog', (questions) => {
			this.$bvModal.show('add-faq');
			this.questions = questions;
		});
	},
	methods: {
		async handleOk(evt) {
			evt.preventDefault();

			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			} else if (this.form.answer == '') {
				this.$toaster.warning('Your answer is required.');
				return;
			}

			this.onSubmit();
		},
		updateFAQFields() {
			this.form.answer = this.form.answer
				.split('<iframe')
				.join('<iframe style="width:500px;height:300px;"');

			// update timestamp
			this.form.createdBy = this.loggedUser.id;
			this.form.dateCreated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
		},
		async onSubmit() {
			this.isLoading = true;

			this.updateFAQFields();

			try {
				await api.createFaq(this.form, this.loggedUser.id);

				this.$bvModal.hide('add-faq');
				this.$toaster.success('New FAQ has been added!');
				EventBus.$emit('onCreateSuccess');
			} catch (error) {
				this.$toaster.error('Error creating FAQ. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			/* Reset our form values */
			this.form = {
				question: '',
				answer: '',
				createdBy: this.loggedUser.id,
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		updateContent(v) {
			this.form.answer = v;
		},
	},
	beforeDestroy() {
		EventBus.$off('showAddFaqDialog');
	},
};
</script>

<style >
.ProseMirror [contenteditable='false'] {
	white-space: normal;
}

.ProseMirror [contenteditable='true'] {
	white-space: pre-wrap;
}

.quill-editor iframe {
	pointer-events: none;
}
</style>